import React from 'react'

const NotFoundPage = () => (
  <section className="hero background is-fullheight">
    <div className="hero-body">
      <div className="container center">
        <div className="content">
          <h1 className="is-size-1 has-text-white">NOT FOUND (404)</h1>
          <p className="has-text-white">You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </div>
    </div>
  </section>
)

export default NotFoundPage
